account-page label {
  cursor: pointer;
  font-weight: bold;
}

account-page registered-users-text {
  font-weight: bold;
}

refreshable-order {
  display: flex;
  justify-content: center;
  align-items: center;
}

.refresh-button {
  margin-left: 8px;
  width: 16px;
}

.refresh-order-spinner {
  position: absolute;
}

.reconnect-spinner {
  position: fixed;
}

.refresh-order-spinner,
.reconnect-spinner {
  top: 50%;
  z-index: 2147483647;
  border: 8px solid var(--percival-medium-grey);
  border-top-color: var(--percival-dark-green);
  height: 80px;
  width: 80px;
}

.account-section,
.country-section {
  margin: 16px 0;
  min-height: 16px;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-details {
  margin-top: 20px;
  margin-bottom: 0px;
}

#country-label {
  font-size: 14px;
  margin: 5px 0;
}

#approval-modal-content {
  width: 600px;
  max-width: 100%;
  height: 90vh;
}

#info-section select-container {
  width: 100%;
  margin-left: 0;
}

.upgrade-btn-container {
  margin-top: 10px;
}

paypal-container {
  display: block;
  width: 100%;
  margin-bottom: 0px;
}

.payment-response-container {
  color: var(--percival-black);
  margin-top: 40px;
}

.select-style {
  display: inline-block;
  margin-left: 10px;
  overflow: hidden;
  height: 28px;
  border: 1px solid var(--percival-dark-green);
  border-radius: 8px;
  box-shadow: inset 0px 2px 3px var(--percival-dimmed-dark-grey);
  background-color: var(--percival-white);
  /* white-space: pre-line; */
  background-color: var(--percival-white);
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.select-style select {
  padding: 5px 8px;
  /* width: 130%; */
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;

  height: 28px;
}

.select-style select:focus {
  outline: none;
}

select-container {
  display: inline-block;
  margin-left: 10px;
  overflow: hidden;
  height: 28px;
  border: 1px solid var(--percival-dark-green);
  border-radius: 8px;
  box-shadow: inset 0px 2px 3px var(--percival-dimmed-dark-grey);
  background-color: var(--percival-white);
  /* white-space: pre-line; */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}

select-container select {
  padding: 5px 8px;
  /* width: 130%; */
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  height: 28px;
  font-size: 14px;
}

select-container select:focus {
  outline: none;
}

@media only screen and (max-width: 450px) {
  .account-section {
    flex-direction: column;
    align-items: flex-start;
  }
}

.pay-button {
  margin-top: 20px;
  width: 230px;
  display: flex;
  align-items: center;
  background: var(--percival-dark-green);
  padding: 2px;
  cursor: pointer;
  border-radius: 8px;
  justify-self: flex-end;
}

#paypal-logo {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  overflow: hidden;
  padding: 6px;
  margin: 4px;
}

paypal-label {
  width: 100%;
  color: var(--percival-white);
  font-weight: bold;
}
