hamburger-menu
{
  z-index: 100;

  -webkit-user-select: none;
  user-select: none;
  margin-right: 16px;
}

hamburger-menu input[type="checkbox"]
{
  display: block;
  width: 80px;
  height: var(--percival-header-height);
  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
hamburger-menu hamburger-line
{
  display: block;
  width: 28px;
  height: 4px;
  background: var(--percival-white);
  border-radius: 2px;
  margin-bottom: 4px;
  position: relative;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

hamburger-menu hamburger-line:last-of-type {
  margin: 0;
}

hamburger-menu hamburger-line:first-child
{
  transform-origin: 0% 0%;
}

hamburger-menu hamburger-line:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
hamburger-menu input:checked ~ hamburger-line
{
  opacity: 1;
  transform: rotate(45deg) translate(-1px, 0px);
  background: var(--percival-dark-green);
}

/*
 * But let's hide the middle one.
 */
hamburger-menu input:checked ~ hamburger-line:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
hamburger-menu input:checked ~ hamburger-line:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0px, -1px);
}

hamburger-links
{
  position: absolute;
  width: 170px;
  margin: -100px 0 0 -50px;
  /* padding: 20px 50px; */
  padding: 120px 20px 10px 50px;
  background: var(--percival-light-grey);
  border: 1px solid var(--percival-grey);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

hamburger-links li
{
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  height: 40px;
  color: var(--percival-dark-grey);
}

/*
 * And let's slide it in from the left
 */
hamburger-menu input:checked ~ hamburger-links
{
  transform: none;
}
