publish-bar {
  background: var(--percival-dark-wood);
  border-radius: 8px;
  height: 46px;
  max-width: 768px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

notify-team {
  display: flex;
  width: 100%;
  column-gap: 8px;
  margin-top: 1.33rem;
  align-items: center;
}

notify-team label {
  cursor: pointer;
}

publish-bar-text {
  margin: 4px 9px;
  font-size: 12px;
  line-height: 14px;
  color: var(--percival-white);
  font-weight: bold;
}

publish-bar-btn {
  margin: 3px;
}

results-visibility {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  column-gap: 4px;
  margin-top: 1.33rem;
}
