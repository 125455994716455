appraisal-subheader {
  width: 100%;
  z-index: 9;
  background-color: var(--percival-dark-grey);
  color: var(--percival-white);
  font-size: 20px;
  text-align: left;
  padding: 16px;
  display: flex;
  grid-column: 1 / span 2;
  min-height: 88px;
  height: intrinsic;           /* Safari/WebKit uses a non-standard name */
  height: -moz-max-content;    /* Firefox/Gecko */
  height: -webkit-max-content; /* Chrome */
  align-items: center;
}

appraisal-question {
  width: 100%;
  margin: auto 0;
  display: flex;
  flex-flow: column;
}

round-question .question-details-anchor {
  margin-left: 12px;
  font-size: 20px;
  color: var(--percival-white);
  font-weight: normal;
}

.subheader-info {
  font-size: 16px;
  color: var(--percival-light-green);
}

info-container {
  display: flex;
  column-gap: 2rem;
}

hamburger-menu {
  display: none;
  /* Center vertically: */
  margin-top: auto;
  margin-bottom: auto;
}

percy-menu {
  display: flex;
  height: 100%;
  justify-content: center;
}

.tab {
  font-size: 16px;
  color: var(--percival-white);
  padding: 0px 20px;
  cursor: pointer;
  font-weight: bold;
}

.tab:hover {
  filter: brightness(85%);
}

.tab.selected {
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 4px;
  border-bottom-color: var(--percival-light-green);
  color: var(--percival-light-green);
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

user-avatar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  color: var(--percival-white);
  font-size: 16px;
  cursor: pointer;
  flex-direction: column;
}

#user-picture {
  display: block;
  cursor: pointer;
}

.coin-icon {
  width: 20px;
  margin-left: 10px;
}

.help-btn {
  margin-right: 12px;
  cursor: pointer;
}

.help-btn:hover {
  filter: brightness(90%)
}

.profile-pic {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

#user-name {
  margin-left: 10px;
}

session-dropdown {
  position: absolute;
  right: 15px;
  top: 55px;
  border-radius: 8px;
  cursor: pointer;
  background: var(--percival-dark-grey);
  z-index: 100;
  display: flex;
  flex-direction: column;
}

session-dropdown button {
  padding: 11px 0;
  margin: 0 10px;
  border-bottom: 1px solid var(--percival-light-green);
  font-size: 14px;
  line-height: 17px;
  color: var(--percival-white);
  text-align: left;
}

session-dropdown button:hover {
  filter: brightness(85%);
}

session-dropdown button:last-of-type {
  border: none;
}

.show {
  display: block;
  cursor: pointer;
}

@media only screen and (max-width: 1080px) {
  appraisal-question {
    font-size: 16px;
    text-align: left;
  }
}

@media only screen and (max-width: 970px) {
  .profile-pic {
    width: 28px;
    height: 28px;
  }
  #user-name {
    display: none;
  }
  percy-menu {
    display: none;
  }
  hamburger-menu {
    display: block;
  }
}

@media only screen and (max-width: 516px) {
  info-container {
    flex-direction: column;
  }
}
