form {
  gap: 15px;
  display: flex;
  flex-flow: column nowrap;
  align-items: baseline;
  width: 100%;
}

form input[type=number]{
  text-align: right;
}

form input[type=submit]{
  align-self: flex-end;
}

form label {
  font-weight: bold;
  cursor: pointer;
}