.cancel-appraisal-button {
  border: 2px solid var(--percival-red);
  box-sizing: border-box;
  border-radius: 12px;
  height: 47px;
  margin: 0 5px;
  padding: 5px 20px;
  font-size: 16px;
  cursor: pointer;
  color: var(--percival-red);
  align-self: center;
}
