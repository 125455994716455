/* Table with team members */

members-list member-info {
  display: grid;
  align-items: center;
  border-bottom: 1px solid var(--percival-dimmed-dark-grey);
  padding: 7px 0;
  cursor: pointer;
  grid-template-columns: 7fr 1fr;
}

members-list member-info:hover {
  background-color: var(--percival-dimmed-light-grey);
}

members-list member-status {
  float: left;
  width: 20%;
  min-width: 80px;
}

members-list member-data {
  grid-column: 1 / span 1;
  display: grid;
  grid-template-columns: 0.3fr 1.1fr 1.3fr 1.3fr;
}

members-list member-data.member-data-with-license-info {
  grid-template-columns: 80px 200px 250px 0.78fr 0.8fr;
}

members-list member-name {
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}

members-list member-role {
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  align-self: center;
  justify-self: left;
  width: 191px;
}

members-list member-email {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}

members-list member-license {
  align-self: center;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  justify-self: center;
}

members-list .modified-attribute {
  background-color: var(--percival-dark-wood);
  width: fit-content;
  padding: 1px;
}

members-list user-picture {
  position: relative;
}

members-list user-picture member-crown {
  position: absolute;
  top: -8px;
  left: 16px;
}

@media only screen and (max-width: 450px) {
  members-list member-data {
    grid-template-columns: 20% 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  members-list member-data.member-data-with-license-info {
    grid-template-rows: repeat(4, 1fr);
  }

  members-list member-email {
    margin: 0;
    grid-row: 2 / span 1;
    grid-column:  2 / span 1;
  }

  members-list member-name {
    margin: 0;
    grid-column: 2 / span 1;
  }

  members-list member-role {
    text-align: left !important;
    grid-column: 2 / span 1;
  }

  members-list member-license {
    text-align: left !important;
    grid-column: 2 / span 1;
  }

  members-list user-picture {
    grid-row: 2 / span 1;
    justify-self: center;
  }

  action-btn .secondary-button.small,
  action-btn .primary-button.small {
    padding: 5px 6px;
  }

  action-btn import-team,
  action-btn send-invites,
  action-btn add-member {
    margin-left: 4px
  }
}
