magic-email-field {
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-flow: column;
  row-gap: 8px;
}

#send-magic {
  align-self: flex-end;
}
