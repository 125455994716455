@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

percy-spinner {
  position: absolute;
  box-sizing: border-box;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid var(--percival-dark-green);
  border-top-color: var(--percival-medium-grey);
  animation: spinner 2s linear infinite;
}

percy-spinner.large {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.secondary-button percy-spinner, .primary-button percy-spinner {
  opacity: 0;
}

.primary-button percy-spinner {
  border: 2px solid var(--percival-white);
  border-top-color: var(--percival-medium-grey);
}

.secondary-button:disabled percy-spinner, .primary-button:disabled percy-spinner {
  opacity: 1;
}