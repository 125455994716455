licenses-history td {
  text-align: center;
}

licenses-history .pending,
licenses-history .paid,
licenses-history .cancelled,
licenses-history .finished {
  font-weight: bold;
}

licenses-history .pending {
  color: var(--percival-dark-wood);
}

licenses-history .paid {
  color: var(--percival-wood);
}

licenses-history .cancelled {
  color: var(--percival-red);
}

licenses-history .finished {
  color: var(--percival-light-green);
}

.history-table-spinner {
  position: fixed;
  top: auto;
  margin-top: 2rem;
}

licenses-history licenses-row {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

@media only screen and (max-width: 620px) {
  licenses-history table {
    min-width: 88%;
    margin-top: 12px;
  }

  licenses-history tr {
    width: 80% !important;
    margin: 0 auto;
  }

  licenses-history table,
  licenses-history thead,
  licenses-history tbody,
  licenses-history th,
  licenses-history td,
  licenses-history tr { 
		display: block; 
	}

  licenses-history thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

  licenses-history tr { 
    border: 1px solid  var(--percival-medium-green);
    margin-bottom: 16px;
  }
	
	licenses-history td { 
		/* Behave  like a "row" */
		border: none;
		position: relative;
		padding-left: 56%; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 40px;
	}
	
	licenses-history td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 10px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}

  licenses-history td:nth-of-type(1):before,
  licenses-history td:nth-of-type(2):before,
  licenses-history td:nth-of-type(3):before,
  licenses-history td:nth-of-type(4):before,
  licenses-history td:nth-of-type(5):before {
    font-weight: bold;
    color: var(--percival-dark-green);
    content: attr(data-before);
  }
}
