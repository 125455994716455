certainty-level {
  display: flex;
  justify-self: center;
  padding-left: 4px;
}

certainty-icon {
  border: 1px solid;
  border-color: var(--percival-medium-green);
  margin: 1px;
  height: 7px;
  padding: 5px;
  cursor: pointer;
}

certainty-icon.active {
  background-color: var(--percival-medium-green);
}

@media only screen and (max-width: 376px) {
  certainty-icon {
    margin-top: 4px;
  }
}
