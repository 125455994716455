#info-section {
  flex-direction: column;
  row-gap: 8px;
  margin-top: 12px;
}

email-field {
  height: 30px;
  padding: 5px 8px;
}

.account-field {
  display: grid;
  width: 100%;
  align-self: flex-start;
}

user-email.account-field {
  display: block;
}

user-billing-info #cnpj-cpf {
  height: 30px;
  padding: 5px 8px;
}

