.percival-logo {
  width: 256px;
  margin-bottom: 24px;
}

.percy-login,
percy-container {
  width: 100%;
  height: calc(100% - var(--footer-height));
  padding-top: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  justify-content: center;
}

percy-container {
  background-color: var(--percival-dimmed-white);
  border-radius: 16px;
  /* height: 450px; */
  width: 620px;
  position: relative;
  /* top: 15%; */
  inset: 50% 50% 50% 0%;
  transform: translate(0, -50%);
}

login-button {
  display: grid;
  background: var(--percival-white);
  color: var(--percival-google-btn);
  transition: background-color .218s,border-color .218s;
  width: 302px;
  max-width: 400px;
  min-width: min-content;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--percival-google-border);
  outline: none;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  grid-template-columns: 21% 0.5fr 4fr;
  margin-top: 16px;
  font-family: 'Google Sans', 'Montserrat', sans-serif;
}

login-button.login-pt-format {
  grid-template-columns: 17.5% 0.5fr 4fr;
}

login-icon {
  grid-column-start: 2;
}

login-button:hover {
  cursor: pointer;
  box-shadow: none;
  border-color: #d2e3fc;
  outline: none;
  background-color: #f7faff;
}

login-button:active {
  background-color: var(--percival-google-active);
}

login-label {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  grid-column-start: 3;
  margin-bottom: 1px;
}

.email-icon {
  margin: 0px 0px 3px 0px;
}

.email-icon img {
  opacity: 80%;
}

#mail-magic {
  width: 100%;
  max-width: 390px;
  border: solid 1px var(--percival-dark-grey);
  color: var(--percival-dark-green);
  padding: 10px;
}

.strong {
  color: var(--percival-dark-green);
  font-weight: bold;
}

#magic-link-how-to {
  white-space: pre-line;
  text-align: center;
  padding: 0 30px;
}

.ico {
  display: inline-block;
  vertical-align: middle;
}

#email-not-received-btn {
  text-decoration: underline;
  cursor: pointer;
}

sign-in-msg,
sign-in-salutation {
  text-align: center;
}

sign-in-salutation {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 18px;
}

sign-in-explanation {
  margin-bottom: 8px;
}

sign-in-msg {
  margin-bottom: 20px;
}

disconnected-page,
login-page,
magic-link-requested-page,
demo-page {
  background-size: cover;
  background-position: center;
  display: block;
  height: calc(100% - var(--footer-height));
}

generic-help-msg {
  text-align: center;
  width: 80%;
}

@media only screen and (max-width: 683px) {
  percy-container {
    height: 340px;
    width: 340px;
    text-align: center;
  }

  sign-in-salutation {
    margin-bottom: 6px;
  }

  login-button {
    width: 172px;
    height: 30px;
  }

  .ico {
    width: 32px;
    height: 32px;
  }

  login-button:nth-of-type(3) .ico {
    height: 27px;
  }

  sign-in-explanation {
    padding: 0 10px;
  }

  magic-link-requested-page percy-container {
    height: fit-content;
    padding: 40px 0;
  }
}
