percy-flash {
  position: fixed;
  max-width: 80%;
  margin-top: 8px;
  top: var(--percival-header-height);
  transform: translate(-50%, 0);
  left: 50%;
  z-index: 2147483647;
}

percy-flash flash-container {
  display: block;
  padding: 16px;
  color: var(--percival-white);
  font-weight: bold;
  border-radius: 8px;
}

.error {
  background-color: var(--percival-red);
}

.success {
  background-color: var(--percival-light-green);
}

.warning {
  background-color: var(--percival-dark-wood);
}

.info {
  background-color: var(--percival-wood);
}

.closebtn {
  margin-left: 15px;
  color: var(--percival-white);
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: var(--percival-black);
}