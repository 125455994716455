percy-team-list {
  margin-top: 20px;
}

percy-team-list table {
  min-width: unset;
}

percy-team-list td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

percy-team-list input {
  margin-right: 12px;
}

percy-team-list buttons-container {
  display: flex;
  justify-content: flex-end;
}

teams-list {
  max-height: 200px;
  overflow: auto;
}

teams-list team-info {
  margin-top: 12px;
  display: grid;
  align-items: center;
  border-bottom: 1px solid var(--percival-dimmed-dark-grey);
  padding: 4px 0;
  cursor: pointer;
  grid-template-columns: 1fr 3fr 5fr 3fr;
}

teams-list team-info label {
  display: flex;
}

teams-list team-info:hover {
  background-color: var(--percival-dimmed-light-grey);
}

teams-list team-name {
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}

teams-list team-admin {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}

teams-list input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: var(--percival-medium-grey);
  width: 1.30em;
  height: 1.30em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.7em;
  height: 0.75em;
  border-radius: 50%;
  transform: scale(0);
  box-shadow: inset 1em 1em var(--percival-light-green);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

@media only screen and (max-width: 450px) {
  teams-list team-info {
    grid-template-columns: 1fr 5fr 3fr;
    row-gap: 4px;
  }

  teams-list team-admin {
    margin: 0;
    grid-row: 2 / span 1;
    grid-column:  2 / span 2;
  }

  teams-list team-name {
    margin: 0;
    grid-column: 2 / span 1;
  }
}
