results-wrapper {
  height: calc(100% - var(--percival-subheader));
}

results-header {
  margin: 5px auto 0;
  background: var(--percival-white);
  border: 1px solid var(--percival-dark-green);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 30px;
  padding: 3px
}

results-header button {
  width: 91px;
  height: 24px;
  text-transform: uppercase;
  font-size: 10px;
  color: var(--percival-medium-green);
  cursor: pointer;
}

results-header button.big {
  width: 142px;
}

results-header button.selected {
  background: var(--percival-light-green);
  box-shadow: 0px 1px 1px var(--percival-dimmed-dark-grey);
  border-radius: 12px;
  color: var(--percival-white);
}

#data-menu {
  display: none;
}

results-list {
  margin: 20px 0;
  display: block;
  overflow-y: auto;
  max-height: 60vh;
  /* overflow-x: scroll; */
}

results-list #final-result-btn {
  margin-left: 0px;
}

results-row {
  display: flex;
  align-items: center;
  height: 62px;
}

results-row select {
  cursor: pointer;
}

results-row:not(:first-child):hover, 
results-row select:hover {
  background-color: var(--percival-dimmed-light-grey);
}

results-pic {
  display: block;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  margin-right: 5px;
  background-color: var(--percival-medium-grey);
  background-repeat: no-repeat;
  background-position: bottom;
  background-origin: center;
  border: 1px solid var(--percival-bluish-grey);
  box-sizing: border-box;
  border-radius: 50%;
}

results-divider {
  display: block;
  height: 62px;
  width: 1px;
  border-right: 1px solid var(--percival-medium-green);
}

graph-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2px;
  /* width: 300px; */
}

.bar-name {
  padding-left: 2px;
  font-size: 15px;
  line-height: 20px;
  color: var(--percival-bluish-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 82%;
}

.bar-name.header {
  padding-left: 4px;
  font-size: 15px;
  line-height: 17px;
  color: var(--percival-bluish-grey);
}

.bar-name.highlighted {
  color: var(--percival-medium-green);
  font-weight: bold;
}

received-appraisal-bar {
  width: 0%;
  background: var(--percival-medium-grey);
  color: var(--percival-bluish-grey);
  height: 17px;
  border-right: 2px solid var(--percival-medium-grey);
}

received-appraisal-bar.highlighted {
  color: var(--percival-medium-green);
  background-color: var(--percival-light-green);
  margin-bottom: 17px;
}

given-appraisal-bar {
  display: block;
  width: 0%;
  border-bottom: 2px solid var(--percival-medium-green);
  border-right: 2px solid var(--percival-medium-green);
}

/* TODO verificar se .given-appraisal-bar red e .given-appraisal-value red ainda fazem sentido */

given-appraisal-bar.red {
  border-bottom: 1px dashed var(--percival-red);
}

given-appraisal-bar bar-value {
  color: var(--percival-medium-green);
}

.given-appraisal-value {
  display: block;
  position: relative;
  font-size: 15px;
  float: right;
  color: var(--percival-medium-green);
  font-weight: bold;
}

.given-appraisal-value.red {
  color: var(--percival-red);
  border-right: 1px solid var(--percival-red);
}

.overflow-arrow {
  position: absolute;
  right: 0;
  display: none;
}

.overflow-arrow img {
  float: right;
}

received-certainty-bar {
  display: block;
  width: 0%;
  background: var(--percival-grey);
  height: 17px;
  color: var(--percival-bluish-grey);
}

received-certainty-bar.highlighted {
  background: var(--percival-light-green);
  color: var(--percival-medium-green);
}

given-certainty-bar {
  display: block;
  width: 0%;
  background: var(--percival-medium-grey);
  height: 17px;
  margin-top: 2px;
  color: var(--percival-bluish-grey);
}

/* TODO classe higlighted não está sendo aplicada */

given-certainty-bar.highlighted {
  background: var(--percival-light-green);
  color: var(--percival-medium-green);
}

bar-value {
  font-size: 15px;
  line-height: 17px;
  position: relative;
  float: right;
  font-weight: bold;
  white-space: nowrap;
}

results-data-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin: 40px 15px 20px 15px;
}

results-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  width: 100%;
}

results-data-container button, results-data-container jux-button {
  width: 250px;
  margin: 5px;
}

.appraisal-name {
  font-size: 15px;
  font-weight: 700;
  line-height: 17px;
  color: var(--percival-medium-green);
  position: relative;
}

results-row.caption-row {
  margin-bottom: 28px;
  height: 102px;
}

results-row.caption-row graph-container {
  position: relative;
}

results-row.caption-row .caption-text {
  position: absolute;
  top: -24px;
}

results-row .caption-bar-value {
  width: 300px;
  left: 320px !important;
}

@media only screen and (max-width: 750px) {
  results-section {
    padding: 0px 5px;
    max-width: 100%;
  }
  results-list {
    overflow-x: scroll;
  }
  .overflow-arrow {
    display: inline-block;
  }
  given-appraisal-bar {
    max-width: 103%;
  }
}