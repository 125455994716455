import-preview-modal-body {
  display: flex;
  flex-direction: column;
}

import-preview-modal-body td img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

import-preview-modal-body label {
  font-weight: bold;
}

import-preview-modal percy-modal modal-wrapper {
  max-width: 100%;
  width: 48%;
}

import-preview-modal-body > p {
  font-weight: bold;
}

.not-enough-licenses-msg {
  margin-top: 1.5em;
  color: var(--percival-red);
}

.invalid-data modal-wrapper {
  width: 40rem;
}

.invalid-data label {
  font-weight: bold;
}

.invalid-data pre {
  overflow: auto;
  font-family: monospace;
  white-space: pre;
  height: 96px;
  background-color: var(--percival-dimmed-light-grey);
}

licenses-info {
  margin-top: 2.5em;
}

licenses-info.not-enough-licenses {
  margin-top: 1.5em;
}

import-preview-modal td {
  padding: 2px 10px;
}

import-preview-modal .collapsible-section table {
  margin-bottom: 1em;
}

import-preview-modal modal-wrapper {
  overflow-y: auto;
}

import-preview-modal members-list member-info {
  grid-template-columns: none;
}

import-preview-modal members-list {
  max-height: none;
}

free-trial-info {
  font-weight: bold;
  margin-top: 2.5em;
}

.added-members, .updated-members, .unaltered-members, .deleted-members {
  font-weight: bold;
}

.deleted-members {
  color: var(--percival-red);
}

@media only screen and (max-width: 1280px) {
  import-preview-modal percy-modal modal-wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 732px) {
  import-preview-modal table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  import-preview-modal table {
    min-width: auto;
  }
}

@media only screen and (max-width: 360px) {
  import-preview-modal table td img{
    width: 32px;
    height: 32px;
  }
}
