accounts-table {
  width: 100%;
  min-height: 250px;
  align-self: center;
}

accounts-table table {
  min-width: unset;
}

accounts-table tbody {
  max-height: 100%;
  height: 100%;
}

accounts-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

accounts-table td.center {
  text-align: center;
}

#super-account modal-wrapper {
  max-width: 56%;
}
