create-team-button {
    display: flex;
    width: 100%;
    flex-flow: column;
}

create-team-button button {
    margin-top: 12px;
    align-self: center;
}

.close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.33rem;
    height: 1.33rem;
    border: none;
    background-color: transparent;
    font-size: 1.33rem;
    transition: 0.16s linear;
    padding: 1.33rem;
    z-index: 10;
    cursor: pointer;
}
  
.close:before,
.close:after {
    position: absolute;
    content: "";
    width: 1.25rem;
    height: 0.125rem;
    background-color: var(--percival-black);
}

.close:before {
    transform: rotate(-45deg);
}

.close:after {
    transform: rotate(45deg);
}

.close:hover:before,
.close:hover:after {
    background-color: var(--percival-red);
}
