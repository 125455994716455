:root {
  /* Percival Palette https://color.adobe.com/pt/Percival-With-Alert-3-color-theme-15763939 */
  --percival-dark-green: #171F1C;
  --percival-medium-green: #0D631E;
  --percival-light-green: #20B03D;
  --percival-wood: #F2C89B;
  --percival-red: #CC5E45;
  /* Percival Palette Variations */
  --percival-dark-wood: #EBAB66;
  --percival-dimmed-wood: #F2C89B55;
  /* Third Party Colors */
  --percival-google-btn: #3c4043;
  --percival-google-border: #dadce0;
  --percival-google-active: #EEE;
  --percival-google-box-shadow: #808080;
  /* Extra Colors */
  --percival-blue: #2B4588;
  --percival-white: #FFF;
  --percival-dimmed-white: #FFFFFFDD;
  --percival-dimmed-light-grey: #DDDDDD70;
  --percival-light-grey: #DDDDDD;
  --percival-grey: #808080;
  --percival-medium-grey: #BABABA;
  --percival-bluish-grey: #3D4550;
  --percival-dimmed-dark-grey: #28282822;
  --percival-dark-grey: #282828;
  --percival-black: #000;
  --percival-dimmed-black: #00000088;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat.ttf');
}


@font-face {
  font-family: 'Google Sans';
  src: url('https://fonts.gstatic.com/s/googlesans/v14/4UabrENHsxJlGDuGo1OIlLU94YtzCwM.ttf')
    format('truetype');
}

body {
  background-color: var(--percival-white);
}

a {
  color: var(--percival-dark-wood);
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  filter: brightness(140%);
}

b {
  font-weight: bold;
}

:root {
  --percival-header-height: 72px;
  --percival-subheader: 88px;
  --percival-question-height: 74px;
  --footer-height: 50px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

percy-body {
  overflow: auto;
  width: 100%;
  height: calc(100% - var(--percival-header-height));
  display: block;
}

percy-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: var(--footer-height);
  font-size: 14px;
  color: var(--percival-white);
  background: var(--percival-dark-green);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

footer-links {
  display: block;
  margin: 10px 0;
}

percy-footer a {
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: var(--percival-white);
  margin: 5px;
}

/**** Utils */

.page-container {
  display: block;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 10px;
  padding-bottom: 16px;
}

.disabled {
  opacity: .5;
  pointer-events: none;
}

.progress {
  cursor: progress;
}

.link {
  color: var(--percival-dark-green);
  text-decoration: underline;
  cursor: pointer;
}

.textfield {
  height: 40px;
  border: 1px solid var(--percival-dark-green);
  border-radius: 8px;
  box-shadow: inset 0px 2px 3px var(--percival-dimmed-dark-grey);
  background-color: var(--percival-white);
  padding: 10px 5px;
  white-space: pre-line;
}

.textfield.small {
  height: 28px;
  /* font-size: 12px; */
}

.textfield:disabled {
  color: var(--percival-medium-grey);
}

.primary-button {
  display: inline-block;
  cursor: pointer;
  color: var(--percival-white);
  border-radius: 8px;
  font-weight: bold;
  background-color: var(--percival-light-green);
  border: 2px solid var(--percival-light-green);
  height: 40px;
  padding: 10px 16px;
  margin-left: 10px;
  position: relative;
  vertical-align: middle;
  line-height: 1;
}

.darker-button {
  background-color: var(--percival-medium-green);
  border: 2px solid var(--percival-medium-green);
}

.primary-button:hover, .secondary-button:hover {
  filter: brightness(140%);
}

.secondary-button {
  display: inline-block;
  cursor: pointer;
  color: var(--percival-medium-green);
  border: 2px solid var(--percival-medium-green);
  border-radius: 8px;
  font-weight: bold;
  height: 40px;
  padding: 10px 16px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  line-height: 1;
}

.primary-button:disabled button-text, .secondary-button:disabled button-text {
  opacity: 0.1;
}

.secondary-button.small, .primary-button.small {
  height: 28px;
  padding: 5px 14px;
  font-size: 12px;
}

.section-title {
  color: var(--percival-medium-green);
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bold {
  font-weight: bold;
}

.right-aligned {
  display: flex;
  justify-content: flex-end;
}

.align-bottom {
  align-items: flex-end;
}

.modal {
  display: flex;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100vh;
  /* Full height */
  overflow: hidden;
  /* Enable scroll if needed */
  background-color: var(--percival-dimmed-black);
  /* Black w/ opacity */
  /*Horizontal and vertical centralized components*/
  display: flex;
  justify-content: center;
  align-items: center;
}

modal-title {
  display: inline-block;
  font-weight: bold;
  color: var(--percival-dark-green);
}

modal-body {
  width: 100%;
}

modal-content {
  max-width: 680px;
  width: 100%;
  background-color: var(--percival-white);
  margin: 20px;
  padding: 20px;
  max-height: 88%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0px 2px 4px var(--percival-dimmed-dark-grey);
  border-radius: 6px;
  display: flex;
  flex-flow: row wrap;
}

modal-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  margin-top: 1.33rem;
}

modal-footer .action {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

modal-footer .action:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--percival-dimmed-dark-grey);
  transition: width 0.16s;
  z-index: 0;
}

modal-footer .action:hover:before {
  width: 100%;
}

.modal-button {
  height: 40px;
  padding: 0 20px;
}

.cancel-btn {
  color: var(--percival-black);
  border-color: var(--percival-black);
}

msg-container, .msg-container {
  padding-top: var(--percival-question-height);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: var(--percival-grey);
  margin-top: 0px;
}

.blank-results-msg-no-padding {
  padding-top: 0px;
}

.anchor-button {
  text-decoration: underline;
  color: var(--percival-dark-green);
  cursor: pointer;
}

#alert-modal {
  display: none;
}

#alert-modal-content {
  width: 300px;
}

alert-modal-text {
  width: 100%;
  margin: 20px 0 10px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: var(--percival-dark-green);
}

dark-overlay {
  display: flex;
  position: fixed;
  /* Stay in place */
  z-index: 8;
  /* Sit on top */
  left: 0;
  top: calc(var(--percival-header-height) + var(--percival-question-height));
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: var(--percival-dimmed-black);
  /* Black w/ opacity */
}

reconnect-overlay {
  position: fixed;
  /* Stay in place */
  z-index: 99;
  /* Sit on top */
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: var(--percival-dimmed-black);
}

.status {
  font-weight: bold;
}

.status-pending {
  color: var(--percival-dark-wood);
}

.status-paid {
  color: var(--percival-wood);
}

.status-cancelled {
  color: var(--percival-red);
}

.status-finished {
  color: var(--percival-light-green);
}

input[type=checkbox] {
  position: relative;
  width: 1em;
  height: 1em;
  color: var(--percival-medium-grey);
  border: 1px solid var(--percival-grey);
  border-radius: 3px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: 0;
  cursor: pointer;
}

input[type=checkbox]::before {
  position: absolute;
  content: "";
  display: block;
  top: -1px;
  left: 4px;
  width: 5px;
  height: 11px;
  border-style: solid;
  border-color: var(--percival-white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

input[type=checkbox]:checked {
  color: var(--percival-white);
  border-color: var(--percival-light-green);
  background-color: var(--percival-light-green);
}

input[type=checkbox]:checked::before {
  opacity: 1;
}

input[type="checkbox"]:disabled {
  border-color: var(--percival-grey);
  background-color: var(--percival-grey);
  cursor: not-allowed;
}

.disabled-label {
  color: var(--percival-grey);
  cursor: not-allowed;
}

@media only screen and (max-width: 618px) {
  /* :root {
    --footer-height: 48px;
  } */
  percy-footer {
    flex-direction: column;
    justify-content: center;
  }
  footer-links {
    margin-top: 7px;
    margin-bottom: 0;
  }
  modal-content {
    padding: 10px;
  }
  copyright-msg {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
}