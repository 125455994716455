percy-modal {
    /* Stay in place */
    position: fixed;
    /* Sit on top */
    z-index: 9999;
    left: 0;
    top: 0;
    /* Full width */
    width: 100%;
    /* Full height */
    height: 100vh;
    /* Enable scroll if needed */
    overflow: hidden;
    /* Black w/ opacity */
    background-color: var(--percival-dimmed-black);
    /*Horizontal and vertical centralized components*/
    display: flex;
    justify-content: center;
    align-items: center;
}

percy-modal modal-wrapper {
    box-sizing: content-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 20%;
    max-width: 40%;
    max-height: 88%;
    margin: 0;
    padding: 1.33rem;
    background-color: var(--percival-white);
    border-radius: 0.3125rem;
    box-shadow: 0 0 1.33rem var(--percival-dimmed-black);
}

percy-modal-content {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    margin-top: 1.33rem;
}

modal-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    color: var(--percival-medium-green);
    font-size: 16px;
    font-weight: bold;
}

modal-header.error-title {
    color: var(--percival-red);
}

modal-header.error-title h3 {
    white-space: pre-line;
}

modal-header h3 {
    font-size: 1.17em;
    margin: 1.33rem 0 0 0;
}
