onboard-modal percy-modal modal-wrapper {
  height: 84vh;
  max-width: 334px;
  min-width: 300px;
  max-height: 600px;
  min-height: 450px;
}

@media only screen and (max-width: 640px) {
  onboard-modal percy-modal modal-wrapper {
    width: 100%;
  }
}

@media only screen and (max-height: 640px) {
  onboard-modal percy-modal modal-wrapper {
    height: 90vh;
  }
}

onboard-modal onboard-page {
  display: none;
  flex-direction: column;
  width: 100%;
}

onboard-modal onboard-img {
  position: relative;
  /* background: url("/images/onboard-1.png") no-repeat; */
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  flex-grow: 1;
  border-radius: 6px 6px 0px 0px;
  height: 306px;
}

onboard-modal onboard-help {
  display: flex;
  justify-content: space-between;
  height: 240px;
}

onboard-modal onboard-message {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.onboard-title {
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: var(--percival-dark-green);
  margin: 5px;
}

.onboard-text {
  height: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: var(--percival-bluish-grey);
  margin-bottom: 5px;
}

onboard-modal onboard-dot-container {
  text-align:center;
}

onboard-modal onboard-dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 6px 2px;
  background-color: var(--percival-medium-grey);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, onboard-dot:hover {
  background-color: var(--percival-dark-green);
}

.onboard-left-btn,
.onboard-right-btn {
  width: 40px;
  height: 100%;
  flex-shrink: 0;
  cursor: pointer;
}

.welcome-modal-row {
  display: flex;
  flex-flow: column;
  margin-top: 20px;
  align-items: center;
  row-gap: 8px;
}

.welcome-modal-row button {
  width: 80%;
}

.welcome-modal-row .primary-button {
  margin-left: 0;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.0s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@media only screen and (max-width: 350px) {
  .onboard-text {
    font-size: 14px;
  }
}
