payment-modal .licenses-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

payment-modal licenses-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}

#licenses-purchase {
  margin-left: 2px;
  width: 60px;
}

#payment-modal modal-wrapper {
  max-width: 80%;
}

#payment-modal-content modal-body {
  padding-top: 16px;
}

licenses-price {
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: var(--percival-black);
}

#beneficiary-text {
  margin-bottom: 16px;
}

.terms-row {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

terms-text {
  margin-left: 4px;
}

.extra-info {
  width: 100%;
  display: block;
}

.terms-label {
  cursor: pointer;
  user-select: none;
}

percy-spinner.spinner-paypal {
  display: none;
  position: absolute;
  top: 60%;
  z-index: 2147483647;
  border: 5px solid var(--percival-medium-grey);
  border-top-color: var(--percival-dark-green);
}
