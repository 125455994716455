table {
  border-collapse: collapse;
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: 600px;
}

th, td {
  border: 1px solid var(--percival-medium-green);
  border-top: none;
  padding: 10px;
}

th {
  background-color: var(--percival-medium-green);
  color: var(--percival-white);
  font-weight: 600;
}

thead {
  flex: 0 0 auto;
  width: 100%;
}

thead tr {
  cursor: default;
}

tbody tr:last-child td {
  border-bottom: none;
}

tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  max-height: 300px;
  border-bottom: #0d631e solid 1px;
}

tbody tr {
  cursor: pointer;
  width: 100%;
}

tbody tr:hover {
  background-color: var(--percival-dimmed-light-grey);
}

thead, tbody tr {
  display: table;
  table-layout: fixed;
}

.img-cell {
  text-align: center;
}

.selected-row {
  background-color: var(--percival-wood);
}

.editable-cell {
  vertical-align: middle;
}

.editable-cell > * {
  vertical-align: middle;
}

.editable-cell:hover {
  cursor: pointer;
  filter: brightness(140%);
}

.highlighted-cell {
  background-color: var(--percival-medium-green);
  color: var(--percival-white);
  font-weight: 600;
}
