licenses-section {
  margin: 8px 0;
  margin-bottom: 10px;
  display: block;
}

licenses-section table {
  min-width: auto;
}

licenses-section td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

licenses-section percy-row {
  display: flex;
  column-gap: 8px;
}

licenses-content {
  height: 192px;
}

licenses-content {
  display: flex;
  flex-flow: column;
  width: 100%;
  row-gap: 8px;
  height: 100%;
}

licenses-section .bold-percy-row {
  font-weight: bold;
}

licenses-section licenses-button {
  align-self: flex-end;
}

users-info {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
