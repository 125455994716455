publish-bar-container {
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  padding: 5px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
