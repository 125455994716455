import-members-modal-body {
  display: flex;
  flex-direction: column;
}

import-members-modal-body > percy-row > label-with-value {
  width: 180px;
  display: contents;
}

excel-download, open-google-sheet {
  display: flex;
  align-items: center;
  row-gap: 8px;
}

import-members-modal-body td img {
  width: 32px;
  height: 32px;
}

import-members-modal-body label {
  font-weight: bold;
}

percy-row {
  display: flex;
  column-gap: 8px;
}

#members-textarea {
  width: 100%;
  height: 96px;
  border: 1px solid var(--percival-dark-green);
  border-radius: 5px;
  box-shadow: inset 0px 2px 3px var(--percival-dimmed-dark-grey);
  background-color: var(--percival-white);
  margin: 10px 0px;
  padding: 10px 5px;
  word-break: break-all;
  /* So the user is not tricked by lines that have been wrapped but are without NEWLINE character at the end.*/
  white-space: pre-line;
}

#file-browser {
  display: none;
}

import-members-modal percy-modal modal-wrapper {
  max-width: 100%;
  width: 48%;
}

@media only screen and (max-width: 683px) {
  import-members-modal percy-modal modal-wrapper {
    max-width: 360px;
    width: 80%;
  }
}
