give-licenses-modal percy-modal modal-wrapper {
  max-width: 680px;
  width: 680px;
}

give-licenses-modal tbody {
  max-height: 240px;
}

give-licenses-modal blank-records-state {
  text-align: center;
  width: 100%;
  display: block;
}

give-licenses-modal percy-modal-content {
  overflow-y: auto;
}

give-licenses-modal history-title {
  display: inline-block;
  font-weight: bold;
  color: var(--percival-dark-green);
  width: 100%;
}

give-licenses-modal licenses-amount {
  font-weight: bold;
}

give-licenses-modal history-title {
  margin-bottom: 8px;
}

give-licenses-modal manage-licenses,
give-licenses-modal observation-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

@media only screen and (max-width: 640px) {
  give-licenses-modal percy-modal modal-wrapper {
    width: 80%;
  }
}
