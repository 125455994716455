/*Admin*/

teams-page, results-wrapper, superadmin-container {
  height: 100%;
  display: block;
  overflow-y: auto;
}

superadmin-section.page-container {
  display: flex;
  height: 100%;
  padding-bottom: 48px;
}

teams-section {
  display: block;
}

teams-section>* {
  margin: 10px 0;
  display: block;
  min-height: 16px;
  min-width: 200px;
}

admin-question, members-actions, appraisal-round-title {
  margin-top: 30px;
}

admin-question {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

admin-question img:hover {
  cursor: pointer;
  filter: brightness(140%);
}

.question-details-anchor {
  margin-top: 12px;
  color: var(--percival-medium-green);
}

#update-question-modal modal-wrapper,
#question-details-modal modal-wrapper {
  width: 40%;
}

@media only screen and (max-width: 683px) {
  #update-question-modal modal-wrapper,
  #question-details-modal modal-wrapper {
    max-width: 360px;
    width: 88%;
  }
}

appraisal-prompt {
  display: block;
  padding-top: 12px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

appraisal-prompt:hover {
  background-color: var(--percival-dimmed-light-grey);
}

members-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

members-actions-btns {
  white-space: nowrap;
}

members-count.align-right {
  display: flex;
  justify-content: flex-end;
}

members-section {
  margin-top: 8px;
  display: block;
}

members-list {
  max-height: 200px;
  overflow: auto;
  display: block;
}

round-control {
  margin-top: 16px;
}

round-status-title {
  font-size: 16px;
  font-weight: bold;
}

team-members-title {
  display: inline-block;
  vertical-align: middle;
}

appraisal-round-title {
  display: block;
}

#update-question-title {
  display: inline-block;
  font-weight: bold;
  color: var(--percival-dark-green);
}

#edit-members-modal-title {
  display: inline-block;
  font-weight: bold;
  color: var(--percival-dark-green);
}

round-status {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.input-text {
  color: var(--percival-black);
}

#question-textarea {
  height: 72px;
}

.question-text-area {
  width: 100%;
  border: 1px solid var(--percival-dark-green);
  border-radius: 5px;
  box-shadow: inset 0px 2px 3px var(--percival-dimmed-dark-grey);
  background-color: var(--percival-white);
  margin: 10px 0px;
  padding: 10px 5px;
  /* So the user is not tricked by lines that have been wrapped but are without NEWLINE character at the end.*/
  white-space: pre-line;
}

#team-member-name, #team-member-email {
  margin-right: 16px;
  width: 180px;
}

add-member, add-team, delete-team {
  display: inline-block;
  vertical-align: middle;
  height: 17px;
  margin-left: 8px;
  cursor: pointer;
}

.deletion-modal modal-wrapper {
  max-width: 80%;
}

.deletion-modal input {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--percival-dark-green);
  border-radius: 5px;
  box-shadow: inset 0px 2px 3px var(--percival-dimmed-dark-grey);
  word-break: break-all;
  white-space: pre-line;
  background-color: var(--percival-white);
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.btn-icon {
  padding-right: 1px;
}

delete-team-button .btn-icon {
  height: 12px;
}

::placeholder {
  font-style: italic;
  word-break: break-all;
  line-break: normal;
}

blank-members-state {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  color: var(--percival-medium-grey);
}

blank-records-state, blank-teams-state {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: 100%;
  color: var(--percival-medium-grey);
}

blank-records-state {
  padding: 20px 0px 40px 0px;
  line-height: 18px;
}

blank-teams-state {
  padding: 10px 0px 10px 0px;
  line-height: 2em;
}

blank-teams-state span {
  color: var(--percival-medium-green);
  margin: 2px 0 2px 0;
}

teamless-admin {
  display: block;
  text-align: center;
  font-size: 18px;
  color: var(--percival-grey);
}

.team-member-status {
  font-size: 10px;
  font-weight: bold;
  width: 76px;
  padding: 4px;
  text-align: center;
  text-transform: uppercase;
  color: var(--percival-white);
  border-radius: 9px;
  margin-left: auto;
  margin-right: 0;
}

selected-indicator {
  float: right;
  background-color: var(--percival-light-green);
}

#clear-appraisals-btn {
  color: var(--percival-red);
  border-color: var(--percival-red);
  margin-top: 12px;
}

.accounts-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

#accounts-label {
  /* font-size: 16px; */
  font-weight: bold;
  margin: 5px 0;
}

#accounts-admins {
  width: 200px;
}

.account-detail {
  font-size: 16px;
  margin-right: 20px;
  display: none;
}

.account-detail.show {
  display: block;
}

#accounts-list {
  width: 100%;
}

.account-column-large {
  float: left;
  width: 80%;
}

.account-column-small {
  text-align: center;
  width: 10%;
}

/* Modals */

close-modal-btn {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 11;
}

.close-modal-img {
  padding: 10px;
}

.close-modal-img:hover {
  filter: brightness(145%);
}

/* Sliders */

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 10px;
}

/* Hide default HTML checkbox */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

round-slider {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  background: var(--percival-white);
  border: 2px solid var(--percival-dark-green);
  border-radius: 10px;
  -webkit-transition: .4s;
  transition: .4s;
  display: block;
}

round-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: var(--percival-dark-green);
  /* border: 2px solid var(--percival-blue); */
  border-radius: 50%;
  -webkit-transition: .4s;
  transition: .4s;
}

round-status label {
  cursor: pointer;
  user-select: none;
}

input:checked+round-slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

input:checked+round-slider {
  border: 2px solid var(--percival-dark-green);
  background-color: var(--percival-light-green);
}

input:checked+round-slider:before {
  background-color: var(--percival-dark-green);
}

percy-teams {
  margin-top: 20px;
}

percy-teams-title {
  width: 100%;
  display: block;
}

/* Responsive css */
@media only screen and (max-width: 683px) {
  #update-allow-appraisals modal-wrapper {
    max-width: 360px;
    width: 80%;
  }
}

@media only screen and (max-width: 546px) {
  members-actions {
    flex-flow: column;
    align-items: baseline;
  }

  members-actions-btns {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@media only screen and (max-width: 403px) {
  members-actions-btns {
    flex-wrap: wrap;
    row-gap: 8px;
  }
}

@media only screen and (max-width: 360px) {
  .secondary-button.small, .primary-button.small {
    height: 28px;
    padding: 5px 10px;
  }
}