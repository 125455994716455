import-team,
send-invites {
  margin-left: 8px;
}

.import-members-btn {
    margin-left: 10px;
}

.png-icon {
  width: 16px;
}

send-invites-wrapper {
  display: flex;
  flex-flow: column;
  row-gap: 1.33rem;
}
