.status-pending {
  color: var(--percival-dark-wood);
}

.status-invited {
  color: var(--percival-dark-wood);
}

.status-appraising {
  color: var(--percival-dark-green);
}

.status-done {
  color: var(--percival-medium-green);
}
