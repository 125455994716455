edit-members-modal .profile-pic {
    width: 80px;
    height: 80px;
}

edit-members-modal percy-modal modal-wrapper {
  flex-flow: column;
}

edit-members-modal percy-modal modal-body {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  row-gap: 8px;
}

edit-members-modal labeled-input {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

#role-label {
  opacity: 0;
  width: 0;
  height: 0;
}

@media only screen and (max-width: 683px) {
  edit-members-modal percy-modal modal-wrapper {
    max-width: 360px;
    width: 80%;
  }
}
