.collapsible-section {
  margin-top: 1em;
}

.collapsible-section > summary {
  list-style-type: none;
}

.collapsible-section summary {
  margin-bottom: 1em;
  cursor: pointer;
}

.collapsible-section summary:hover {
  background-color: var(--percival-dimmed-light-grey);
}

.collapsible-section summary p {
  display: inline;
}
