members-pool {
    padding: 8px;
    background: var(--percival-wood);
    overflow-y: auto;
    display: flex;
    flex-flow: column;
}

user-list {
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 8px;
    height: 100%;
}

@media only screen and (max-width: 410px) {
    user-list {
      align-items: flex-start;
    }
}
