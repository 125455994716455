results-paywall {
  height: 300px;
}

results-paywall {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 0px 8px 8px var(--percival-white);
  margin: 0 auto;
}

results-paywall-content {
  display: flex;
  flex-flow: column;
  height: 64%;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  justify-content: space-evenly;
  background-color: var(--percival-dimmed-wood);
  width: 500px;
}

results-paywall p {
  display: inherit;
  text-align: center;
}

results-paywall .bold-percy-row {
  font-weight: bold;
}
