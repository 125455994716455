appraisal-levels {
  overflow-y: auto;
  grid-column: 1 / span 1;
}

appraisal-level {
  overflow: hidden;
  min-height: 90px;
  padding: 8px 0px;
  display: block;
}

appraisal-level+appraisal-level {
  border-top: 2px dashed var(--percival-grey);
}
