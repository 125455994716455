team-name-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

team-name-section img:hover {
  cursor: pointer;
  filter: brightness(140%);
}

team-name-prompt {
  display: block;
  padding-top: 12px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

team-name-prompt:hover {
  background-color: var(--percival-dimmed-light-grey);
}
