#certainty-pic {
  border-radius: 50%;
  width:  105px;
  height: 105px;
  margin: 0 5px;
}

.certainty-name {
  color: var(--percival-dark-grey);
  font-size: 36px;
  font-weight: bold;
  margin: 5px 5px 0px 5px;
  text-align: left;
  display: inline-block;
}

.certainty-email {
  color: var(--percival-medium-green);
  margin: 0 0 5px 5px;
  font-size: 16px;
  text-align: left;
  display: block;
}

certainty-question {
  color: var(--percival-dark-grey);
  font-size: 16px;
  margin: 12px 5px 0px 5px;
}

certainty-container {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 48px;
}

.certainty-buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr 2.25fr 3fr;
  grid-template-rows: 1fr;
}

certainty-header {
  max-width: 522px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
