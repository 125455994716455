appraisals-page {
  display: grid;
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  grid-template-columns: 1fr 228px;
}

.drag-target {
  background-color: var(--percival-dimmed-light-grey);
}

@media only screen and (max-width: 640px) {
  appraisals-page {
    grid-template-columns: 1fr;
  }
}
