.card {
  display: flex;
  float: left;
  background-color: var(--percival-white);
  box-shadow: 0 2px 4px 0 var(--percival-dark-grey);
  width: 180px;
  border-radius: 6px;
  margin: 5px;
  padding: 10px;
  cursor: grab;
}

card-data {
  display: grid;
  flex-wrap: wrap;
  width: 100%; 
  grid-template-rows: 1fr auto;
  justify-items: flex-end;
}

.card:hover {
  box-shadow: 0 4px 6px 0 var(--percival-dark-grey);
}

.dragging {
  opacity: 0.4;
}

.card-name {
  color: var(--percival-dark-grey);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  font-weight: normal;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-top: -4px;
  height: fit-content;
  line-height: 1.3;
  width: 100%;
  text-align: center;
  padding-left: 4px;
}

.card-pic {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  flex-shrink: 0;
}

appraisals-page profile-picture {
  width: 54px;
  box-sizing: border-box;
  height: 54px;
}

@media only screen and (max-width: 565px) {
  .card {
    width: 110px;
    padding: 10px 6px;
    margin: 4px;
  }

  card-data {
    justify-content: flex-start;
  }

  .card-pic {
    width:  40px;
    height: 40px;
    display: none;
  }

  .card-name {
    margin-left: 0px;
    text-overflow: ellipsis;
    width: 96px;
  }
}

@media only screen and (max-width: 325px) {
  .card {
    width: 92px;
  }

  .card-name {
    width: 85px;
  }

}
