#contact-us-modal form {
  gap: 0;
}

contact-field {
  width: 100%;
  display: block;
}

#contact-us-modal input[type=text],
#contact-us-modal input[type=email],
#contact-us-modal input[type=tel],
#contact-us-modal textarea,
#contact-us-modal select
{
  width: 100%;
  padding: 12px;
  border: 1px solid var(--percival-dark-green);
  border-radius: 5px;
  box-shadow: inset 0px 2px 3px var(--percival-dimmed-dark-grey);
  word-break: break-all;
  white-space: pre-line;
  background-color: var(--percival-white);
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

#contact-us-modal textarea {
  height: 200px;
}

#contact-us-modal button {
  margin-top: 8px;
  margin-bottom: 8px;
  align-self: flex-end;
}

@media only screen and (max-height: 640px) {
  #contact-us-modal textarea {
    height: 96px;
  }
}