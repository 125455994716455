 /**** Limpar as formatações do CSS */
 @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap');
body {
  font-family: 'Open Sans', Sans-Serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  font-size: 14px;
  /*-webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;*/
}
* {
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  line-height: inherit;
  background: none;
  border: none;
  box-sizing: border-box;
  white-space: initial;
  outline: none;
  cursor: inherit;
}
*::before, *::after {
  font: inherit;
  color: inherit;
  box-sizing: inherit;
}


.force-display-none {
  display: none !important;
}
