certainty-button {
  display: flex;
  margin: 0 5px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

certainty-description {
  color: var(--percival-dark-grey);
  font-size: 16px;
  text-align: center;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

certainty-button button {
  height: 47px;
  box-shadow: 0 4px 8px 0 var(--percival-dimmed-dark-grey);
  border: 1px solid;
  border-color: var(--percival-medium-green);
  color: var(--percival-medium-green);
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
}

certainty-button button.active {
  background-color: var(--percival-medium-green);
  color: var(--percival-white);
}
