edit-team-modal percy-modal modal-wrapper {
  flex-flow: column;
}

edit-team-modal percy-modal modal-body {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  row-gap: 8px;
}

@media only screen and (max-width: 683px) {
  edit-team-modal percy-modal modal-wrapper {
    max-width: 360px;
    width: 80%;
  }
}
