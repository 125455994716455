percy-header {
    height: var(--percival-header-height);
    padding: 0px 16px;
    background: var(--percival-dark-green);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

percy-header.impersonating {
    background-color: var(--percival-red);
}

percy-header>header-logo {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    justify-self: flex-start;
}

percy-header>percy-session {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
}

@media only screen and (max-width: 970px) {
    percy-header>header-logo {
        justify-self: center;
      }
}
