percy-floating-button {
  position: fixed;
}

percy-floating-button img {
  margin-right: 8px;
}

percy-floating-button button {
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.top-floating {
  inset: 0% 50% 100% 50%;
  transform: translate(-50%, 0);
}

.bottom-floating {
  inset: 100% 50% 0% 50%;
  transform: translate(-50%, 0);
}

.left-floating {
  inset: 50% 100% 50% 0%;
  transform: translate(-50%, 0);
}

.right-floating {
  inset: 50% 0% 50% 100%;
  transform: translate(-50%, 0);
}

.bottom-left-floating {
  inset: 100% 100% 0% 0%;
  transform: translate(-50%, 0);
}

.bottom-right-floating {
  top: 90%;
  left: 70%;
}

.top-left-floating {
  inset: 0% 100% 100% 0%;
  transform: translate(-50%, 0);
}

.top-right-floating {
  inset: 0% 0% 100% 100%;
  transform: translate(-50%, 0);
}
