order-details-modal percy-modal modal-wrapper {
  max-width: 550px;
  width: 550px;
}

@media only screen and (max-width: 640px) {
  order-details-modal percy-modal modal-wrapper {
    width: 80%;
  }
}

order-details-modal modal-body {
  width: 80%;
  display: grid;
  margin: 0px auto;
}

.order-row-title {
    font-weight: bold;
}

.order-details-row {
    width: 100%;
    display: flex;
    padding: 8px;
    justify-content: space-between;
}

.approval-link-spinner {
    top: 86%;
    left: 76%;
}

.pop-up-spinner {
    position: absolute;
    top: 50%;
    z-index: 2147483647;
    border: 8px solid var(--percival-medium-grey);
    border-top-color: var(--percival-dark-green);
    height: 80px;
    width: 80px;
}

.fullscreen-overlay {
    top: 0;
}

order-details-modal paypal-buy-now {
    justify-self: flex-end;
    min-height: 40px;
}
