certainty-modal percy-modal modal-wrapper {
  max-width: 640px;
}

certainty-modal modal-body {
  display: flex;
  flex-flow: row wrap;
}

certainty-modal percy-spacer {
  display: none !important;
}

@media only screen and (max-width: 683px) {
  certainty-modal percy-modal modal-wrapper {
    max-width: 360px;
    width: 80%;
  }
}
