social-login-modal percy-spacer {
  display: block;
}

social-login-modal login-button {
  margin: 0 auto;
}

social-login-modal a {
  text-align: center;
  color: var(--percival-light-green);
  width: 100%;
  display: block;
  margin-top: 12px;
  text-decoration: none;
}

social-login-modal p {
  margin-bottom: 8px;
}
